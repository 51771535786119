.yearChoice{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.yearChoice select{
    width: 50%;
}

.bold{
    font-weight: bold;
}

.statCategory{
    font-size: 1.2rem;
    width: auto;
}

.totals{
    background-color: rgba(132, 231, 132, 0.26);
    font-weight: bold;
    text-align: center;
}

.headers{
    font-weight: bold;
    background-color: lightgray;
    text-align: center;
}

td{
    text-align: center;
}

.chart{
    display: flex;
    width: 50%;
    justify-content: center;
    margin:auto;
}