.bikeCatalog{
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 900px;
    
  }
  
  .bikeBox{
    width: calc(50% - 40px);
    height: auto;
    background-color: #76b538;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px lightgray;
    position: relative;
  }

  .errorMessage{
    text-align: center;
    color: red;
    font-weight: bold;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
  }

  .bikeTag{
      position: absolute;
      top: -10px;
      padding: 10px;
      right: -10px;
      background-color: white;
      border-radius: 10px;
      width: auto;
      height: 40px;
      border: 1px solid #5b2b9c;
      box-shadow: 2px 2px 2px darkgreen;
      text-align: center;
  }

  .bikeDescription{
    width: 90%;
    margin: 5px 0 5px 0;
    padding: 0px;
    text-align: left;
    font-size: small;
}

.bikeImage{
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
}

.bikeImage img{
    width: 100%;
    object-fit: contain;
    margin: 0;
    padding: 0;
    /* border: 4px solid white; */
    max-height: 260px;
}

.reserveButton button{
    margin: 5px;
    padding: 5px;
    border: 1px solid #5b2b9c;
    background-color: #5b2b9c;
    color: white;
    font-size: 1rem;
    box-shadow: 2px 2px 2px darkgreen;
    border-radius: 5px;
    position: absolute;
    left: calc(50% - 60px);
    bottom: -20px;
    width: 100px;
}

.chosenBike{
    display: block;
    width: 70%;
    margin: 0 auto;
}

#cancelButton{
    margin: 5px;
    padding: 5px;
    border: 1px solid #5b2b9c;
    background-color: #5b2b9c;
    color: white;
    font-size: big;
    box-shadow: 2px 2px 2px darkgreen;
    border-radius: 5px;
    position: absolute;
    left: calc(50% - 60px);
    /* bottom: -50px; */
    width: 100px;
}

#cancelButton:active{
    background-color: black;
    color: white;
}

.bikeImageFile{
  position: relative;
}
  .magGlass{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20%; 
    left: 50%;
    opacity: 0.9; 
    transform: translate(-50%, -50%);
  }

  .magGlass:hover{
    width: 40px;
    height: 40px;
    opacity: 1; 
  }

  /* .popup-container {
    position: relative;
  } */
  

  .clickToClose{
    text-align: center;
    background-color: none;
  }
  .popup {
    position: fixed;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: white;
    border: 1px solid green;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
   
    .bikeBox{
      width: 100%;
    }

    .popup {
      position: fixed;
      width: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* padding: 10px; */
      background-color: white;
      /* border: 1px solid #ccc; */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      z-index: 1000;
    }
  
  }