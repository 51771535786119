/* Setup */
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0 auto;
  background-color: white;
}

main {
  margin: 0 auto;
  max-width: 1200px;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

.headerText {
  background-color: rgba(255, 255, 255, 0.8);
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
}

.headerText p {
  font-size: 1.2rem;
  text-align: center;
  color: #5b2b9c;
  font-weight: bold;
  padding: 0;
  margin: 10px;
}

figcaption,
figure {
  display: block;
  margin-top: 100px;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
  width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

button,
fakeButon,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px;
  margin: 5px;
  width: 100%;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

/* textarea {
  overflow: auto;
} */

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: auto
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: "";
  clear: both;
  height: 0;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

p,
li {
  font-size: medium;
}

#content {
  padding-left: 10px;
}

.content {
  padding: 0px;
}

a {
  color: white;
  text-decoration: none;
}

h3 {
  text-align: center;
  color: #5b2b9c;
  position: relative;
  font-size: 2rem;
}

h3::after {
  content: "";
  position: absolute;
  top: 90%;
  left: 35%;
  width: 30%;
  height: 10%;
  background: #76b538;
  z-index: -1;
}

.contentCard {
  padding: 0px;
  margin-bottom: 100px;
}

/* FILTER */
.filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.erroNoLogin {
  width: 100%;
  color: red;
  font-size: 20px;
  text-align: center;
}

.loadingWarning {
  text-align: center;
}

.linkInsideFAQ {
  color: black;
  text-decoration: underline;
}

.hiddenDiv {
  display: none;
}

.simpleLink {
  color: black;
  text-decoration: underline;
}

.wcnLink {
  color: green;
  font-size: 1.5rem;
  text-decoration: underline;
}

/* Hamburguer Menu */
nav {
  background: #5b2b9c;
  padding: 0 15px;
}

a {
  color: white;
  text-decoration: none;
}

.menu,
.submenu {
  list-style-type: none;
}

.logo {
  font-size: 20px;
  padding: 7.5px 0 7.5px 0;
  position: relative;
}

.logo a:hover,
.logo a:focus {
  color: #76b538;
}

.logo a:hover::after,
.logo a:focus::after {
  content: "";
  position: absolute;
  bottom: 20%;
  left: 25%;
  width: 50%;
  height: 5%;
  background: #76b538;
  z-index: 10;
}

.item {
  padding: 10px;
  position: relative;
}

.item.button {
  padding: 9px 5px;
}

.item:not(.button) a:hover,
.item:not(.button) a:focus,
.item a:hover::after,
.item a:focus::after {
  color: #76b538;
}

.item a:hover,
.item a:focus,
.logo a:hover,
.logo a:focus {
  font-weight: bold;
}

.item a:hover::after,
.item a:focus::after {
  content: "";
  position: absolute;
  bottom: 20%;
  left: calc(50% - 40px / 2);
  width: 40px;
  height: 2px;
  background: #76b538;
  z-index: 10;
}

/* .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
} */

.menu li a {
  display: block;
  padding: 5px 5px;
}

.menu li.subitem a {
  padding: 15px;
}

.toggle {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  font-size: 20px;
}

.item.button {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.item {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
}

.active .item {
  display: block;
}

.button.secondary {
  /* divider between buttons and menu links */
  border-bottom: 1px #444 solid;
}

/* Submenu up from mobile screens */
.submenu {
  display: none;
}

.submenu-active .submenu {
  display: block;
}

.has-submenu i {
  font-size: 12px;
}

.has-submenu>a::after {
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  line-height: 16px;
  font-weight: 900;
  content: "\f078";
  color: white;
  padding-left: 5px;
}

.subitem a {
  padding: 10px 15px;
}

.submenu-active {
  background-color: white;
  border-radius: 3px;
}

/* CSS files add styling rules to your content */
html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
}

.wrapper {
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
  /*   text-align: center; */
}

.centering {
  text-align: center;
}

header {
  /* background-color: #5b2b9c; */
  /* background-image: url(../images/cover2023.jpg); */
  /* background-position: center center; */
  /* background-size: cover; */
  position: relative;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  padding-top: 50px;
  padding-bottom: 100px;
}

.mainTextStyle {
  z-index: 1;
  position: absolute;
  bottom: 10vh;
  left: 0px;
  right: 0px;
}

.positionRelative {
  position: relative;
  top: 100px;
}

/* header img {
  width: 80%;
  margin-top: 50px;
} */

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0px;
  width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky{
  margin-top: 50px;
}

.allContent {
  position: relative;
  top: 150px;
  margin-bottom: 200px;
}

main {
  position: relative;
  top: 0px;
}

.banner{
  position: relative;
}

.mainTitle {
  text-align: center;
  font-size: 3em;
  padding-top: 100px;
  color: white;
  text-shadow: 2px 2px 5px #5b2b9c;
}

.pausedMessage p {
  text-align: center;
  font-size: 2rem;
}

/* Style the navbar */
nav {
  background-color: #5b2b9c;
  /* z-index: 100; */
}

#helpBikeSizeB {
  border-radius: 50px;
  background: #5b2b9c;
  border-color: #5b2b9c;
  color: white;
  font-size: medium;
}

#helpBikeTypeB {
  border-radius: 50px;
  background: #5b2b9c;
}

button,
.fakeButton,
button:active,
.fakeButton:active {
  border: none;
  font-size: 1.2rem;
  /* -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75); */
  /* box-shadow:5px 5px 5px 0px rgba(0, 0, 0, 0.75); */
}

.fakeButton {
  padding: 10px;
  margin-top: 50px;
}

button:active,
.fakeButton:active {
  /* -webkit-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.75); */
  /* box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.75); */
  position: relative;
  /* right: -5px; */
  /* top: 5px; */
}

button:disabled,
.fakeButton:disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: lightgray;
  right: 0px;
  top: 0px;
  color: white;
}

.buttonDonate,
.buttonDonate:active,
.fakeButton,
.fakeButton:active {
  font-size: 1rem;
  color: white;
  width: 50%;
}

.helpButton,
.helpButton:active {
  margin: 5px;
  padding: 0 5px;
  background-color: white;
  color: #5b2b9c;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
}

.deleteButton,
.deleteButton:active {
  margin: 5px;
  padding: 10px 10px;
  background-color: gray;
  font-size: 13px;
  display: none;
}

.termsAndConditions,
.termsAndConditions li {
  margin: 0 auto;
  text-align: justify;
  padding: 0px;
  color: black;
  font-size: 1rem;
  border-bottom: 50px;
}

.termsAndConditions p,
.termsAndConditions li {
  font-size: 1rem;
}

.termsAndConditions a {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

input[type=radio] {
  border: 0px;
  width: 50px;
  height: 25px;
}

input#haveBike,
input#needBike,
input#gotBike {
  width: 30px;
  height: 30px;
}

label,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="radio"],
input[type="date"],
input[type="number"],
select,
textarea {
  padding: 10px;
  margin: 5px;
}

select,
input[type="text"],
input[type="email"],
input[type="date"],
input[type="password"],
input[type="number"],
textarea {
  width: 100%;
  /* overflow: hidden; */
  /* white-space: pre; */
  text-overflow: ellipsis;
  /* -webkit-appearance: none; */
  background-color: white;
}

textarea {
  resize: vertical;
}

.bikeTypeBox {
  padding-top: 10px;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bikeTypeBox div {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
}

.styleNeedHave {
  text-align: center;
  color: inherit;
  position: relative;
  padding: 20px;
  font-size: 1.2em;
}

.styleNeedHave p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 35%;
  width: 30%;
  height: 50%;
  background: #76b538;
  z-index: -1;
}

input#heightCm.heightCm {
  width: 180px;
}

input#height_cm {
  width: 180px;
}

/*  Match flow styling: */
/*  =================== */
.mailButton,
.mailButton:active {
  background: #eeeeee;
  color: #5b2b9c;
  padding: 15px;
  -webkit-box-shadow: 2px 2px #76b538;
  box-shadow: 2px 2px #76b538;
  font-size: 0.8rem;
  width: 100px;
  font-weight: bold;
}

.matchFlowDiv {
  padding: 0;
  margin: 0 0 10px 0;
  border: 1px dashed #5b2b9c;
  padding-bottom: 10px;
}

.matchFlowButtons {
  margin: 0 auto;
  padding: 0 15px 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.matchFlowPeople {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px;
}

.matchFlowPeople div {
  padding: 0;
  margin: 0px;
  width: calc(100%);
}

.matchFlowButtons button {
  padding: 5px 5px 5px 5px;
  font-size: 0.8rem;
  color: blue;
  background: #eeeeee;
  -webkit-box-shadow: 3px 1px 1px #76b538;
  box-shadow: 3px 1px 1px #76b538;
  width: calc(30% - 40px);
  min-width: 90px;
  min-height: 50px;
  font-weight: bold;
}

.bikeImageMatchFlowDiv {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.bikeImageMatchFlow {
  margin-left: 40px;
  padding: 0px;
  width: 80%;
}

.donorLog,
.recipientLog {
  padding-top: 20px;
  padding-left: 40px;
}

.donorLog p,
.recipientLog p {
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}

.donors,
.recipients,
.final {
  line-height: 1.4;
}

.donors li,
.recipients li,
.final li {
  font-size: 14px;
}

.donors ol,
.recipients ol,
.final ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.donors li,
.recipients li,
.final li {
  background-color: white;
  border: 1px solid #5b2b9c;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  width: calc(50% - 30px);
  min-width: 280px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  list-style: none;
}

.donors h4,
.recipients h4,
.final h4 {
  margin: 0;
  padding: 0;
}

.viewButtonBoxDiv {
  width: 80%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.viewButtonBox {
  width: 30%;
  min-width: 200px;
  max-width: 250px;
  margin: 10px 10px;
  padding: 20px;
}

.viewButtonBox:active,
.viewButtonBox:focus,
.viewButtonBox:selection {
  color: grey;
}

.downloadButton {
  display: none;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 150px;
}

.downloadButton button {
  width: 30%;
  margin: 30px;
  min-width: 150px;
  max-width: 300px;
}

.showHelpBikeSize img {
  width: 100%;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 2em;
  background-color: #76b538;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 0.8rem;
  padding: 1em;
  margin-top: 150px;
}


/* Tablet menu */
@media all and (min-width: 700px) {
  /* header {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center top;
    height: 100vh;
  } */

  /* header img {
    width: 70%;
    max-width: 350px;
    margin-top: calc(50px - 2vw);
  } */

  /* .menu {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  } */
  .logo {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .item.button {
    width: auto;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: block;
  }

  .toggle {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  /* Button up from tablet screen */
  .menu li.button a {
    padding: 10px 15px;
    margin: 5px 0;
  }

  .button a {
    background: #0080ff;
    border: 1px royalblue solid;
  }

  .button.secondary {
    border: 0;
  }

  .button.secondary a {
    background: transparent;
    border: 1px #0080ff solid;
  }

  .button a:hover,
  .button a:focus {
    text-decoration: none;
  }

  .button:not(.secondary) a:hover,
  .button:not(.secondary) a:focus {
    background: royalblue;
    border-color: darkblue;
  }

  .bikeTypeBox div {
    width: 100%;
  }

  .menu li a {
    padding: 10px;
  }

  .item {
    padding: 0;
  }

  .viewButtonBox:active,
  .viewButtonBox:focus,
  .viewButtonBox:selection {
    color: grey;
  }
}

.divStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: top center;
  margin-top: 50px;
  height: 85vh;
}

@media all and (min-width: 1200px) {
  .divStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: top center;
    height: 85vh;
}

  .viewButtonBox:active,
  .viewButtonBox:focus,
  .viewButtonBox:selection {
    color: grey;
  }

  /* .menu {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    background: none;
  } */
  .logo {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }

  .item {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    display: block;
    width: auto;
  }

  button {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .submenu-active .submenu {
    display: block;
    position: absolute;
    left: 0;
    top: 68px;
    /*     background: #111; */
  }

  .toggle {
    display: none;
  }

  .submenu-active {
    border-radius: 0;
  }

  .menu li a {
    padding: 15px;
  }

  .bikeTypeBox div {
    width: 100%;
  }
}

select[id="heightIn"],
select[id="heightFt"] {
  width: 60px
}

select[id="height_in"],
select[id="height_ft"] {
  width: 60px
}

input[id="calculatedBikeSize"] {
  background-color: lightgray;
}

menu {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0px;
  padding-top: 20px;
  padding-right: 10%;
  padding-left: 10%;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  z-index: 2;
}

.loginButton {
  text-align: right;
}


.menuAll {
  width: 90%;
  display: flex;
}

.menuHome {
  width: 10%;
  text-align: left;
  /* z-index: 100 */
}

.menuHome img {
  margin: 0;
  /* width: auto; */
  object-fit: cover;
}



.MainButtons {
  padding-left: 10px;
}

.MainButtonsButton,
.adminButton {
  height: auto;
  /* text-align: right; */
  padding: 20px;
  margin: 0px;
  background-color: white;
  box-shadow: none;
  border-radius: 10px;
  color: #76b538
}

.MainButtonsButton:hover,
.adminButton:hover {
  color: #3a6b09
}

.otherButton {
  color: white;
  background-color: #76b538;
  width: 50%;
  padding: 20px;
  margin: 10px;
  font-size: 1.5rem;
  border-radius: 10px;
}

.releaseAllBikesButton{
  color: white;
  background-color: #5b2b9c;;
  padding: 10px;
  margin: 5px;
  font-size: 1.2rem;
  border-radius: 10px;

}

.bikeTypeBox {
  display: flex;
  flex-direction: row;
}

.bikeTypeBox div {
  width: 50%
}

.bikeTypeBox input[type=radio] {
  border: 0px;
  /* height: 1.5rem; */
}

.frameTypeImages {
  width: 30%;
}

.simpleButton,
.simpleButton:hover,
.simpleButton:active {
  margin: 0;
  padding: 5px 0;
  background-color: transparent;
  color: green;
  box-shadow: none;
}

.showHelp,
.showHelp:hover,
.showHelp:active {
  position: fixed;
  border-radius: 50%;
  top: 160px;
  right: 150px;
  padding: 0px;
  height: 30px;
  width: 30px;
  box-shadow: 2px 2px 5px darkgray;
}

.main {
  width: 80%;
  margin: 0 auto;
}

.mainPageContent {
  width: 80%;
  margin: 0 auto;
}

.loginButton {
  background-color: transparent;
  box-shadow: none;
  color: green;
  margin: 0;
  padding: 0;
}

.fakeButton {
  background-color: gray;
  height: auto;
  margin: 10px;
  padding: 30px;
  font-size: 1.2rem;
  width: 100%;
}

.fakeButton:active {
  box-shadow: none;
  background-color: gray;
  height: auto;
}

th,
td {
  padding: 10px;
  margin: 0;
  text-align: left;
  border: 2px solid black;
  width: 400px;
}

td {
  width: calc(100% / 6);
}

.locations {
  text-align: center;
  width: 80%;
  margin: 10px auto;
}

.locations ol {
  padding: 0;
  margin: 10px auto;
}

.locations li {
  margin: 10px auto;
  padding: 0;
  text-align: left;
}

.chooseDateTime {
  padding: 5px 0;
}

.chooseDateTime label {
  margin: 0;
  padding: 0px;
  width: 250px;
}

.chooseDateTime select {
  margin: 10px 0px;
  padding: 5px;
  width: 100%;
  border: none;
  text-align: left;
  border-radius: 5px;
  /* font-size: small; */
}

#disabledReserveButton {
  background-color: gray;
  border: none;
  /* width: 70%; */
  /* left: calc(15%); */
}



.errorBox {
  color: red;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
}

.centeredP {
  text-align: center;
  margin: 0 auto;
}

.ccbuttons {
  display: flex;
}

.cannotCancel {
  text-align: center;
  padding: 20px;
  margin: 10px;
  background-color: white;
  text-align: center;
}

.buttonColumn {
  padding: 0;
  margin: 0;
}

.finalConfirmButton,
.finalCancelButton {
  box-sizing: border-box;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  font-size: small;
  color: white;
  box-shadow: 2px 2px #101906;

}

.finalConfirmButton {
  background-color: #2f4915;
}

.finalCancelButton {
  background-color: darkred;
}

.Xhidden {
  display: none;
}


.halfSplit {
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: 0;
  border: 1px solid #5b2b9c;
}

.halfSplit img {
  width: 90%;
  margin: 0 auto;
}


.menuHomeButton {
  padding: 0;
  margin: 0;
  background-color: white;
}


.regularMenu {
  display: flex;
  width: 100%;
  /* margin-right: 10%; */
}

@media (max-width: 1000px) {

  .menuHome{
    width: 70px;
  }
  .menuAll{
    width: calc(100% - 70px);
  }

  .showHelp,
  .showHelp:hover,
  .showHelp:active {
    top: 100px;
    right: 10px;
  }

  .halfSplit {
    width: 100%;
  }

  .locations {
    width: 100%;

  }

  .halfSplit {
    display: flex;
    flex-direction: column;
  }

  .textLocal {
    padding: 10px;
    text-align: center;
  }

  .imageLocal {
    width: 50%;
    margin: 0 auto;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 30px;
  }

  .Xhidden {
    display: block;
    /* z-index: 15; */
  }

  .MainButtonsButton,
  .adminButton,
  .loginButton {
    font-size: 1rem;
    text-align: right;
    padding: 10px;
    margin: 0;
    background-color: white;
    border-radius: 0;
  }

  .regularMenu {
    display: none;
  }

  .hamburger_menu_options {
    width: 100%;
  }

  .allContent {
    top: 80px;
  }

  .wrapper {
    width: 95%;
    margin: 0 auto;
    padding: 0;
  }

  header {
    margin-top: 0;
    padding: 0;
  }

  .header h1 {
    margin-top: 0px;

  }

  .headerText {
    width: 90%;
  }

  .headerText p {
    font-size: 1rem;
  }

  .ham_menu {
    width: 40px;
  }

  .menuHome img {
    object-fit: contain;
  }

  menu {
    padding: 0;
    padding-right: 10px;
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
  }

}

.errorBox {
  color: red;
}

menu img,
.regularMenu img {

  padding: 0;
  margin: 0;
}


.chosenBike {
  max-width: 600px;
}

.imageLocal {
  padding: 10px;
}

.textLocal {
  padding-top: 20px;
  min-width: 200px;
}

.locations ol li {
  list-style-type: none;
}

.redButton {
  background-color: red;
}

.redButton:hover {
  background-color: rgb(79, 6, 6);
}

.displayFlex {
  display: flex;
}

#createBackupButton, #createCSVButton {
  background-color: #5b2b9c;
  font-size: small;
  
  width: 50%;
}

/* *{
  border: 1px solid red;
} */

.note{
  padding: 0 10px;
  margin: 0;
}

.bikeLog{
  font-size: small;
}



.recoverPasswordButton button{
  margin: 0;
  padding: 0;
  color: blue;
  background-color: transparent;
  font-size: small;
  text-decoration: underline;
  display: inline;
  width: auto;
}

.textDeleted{
  color: red;
}
.textReserved{
  color: rgb(46, 46, 98);
}
.textAvailable{
  color: green;
}

.grayBackground{
  background-color: lightgray;
}

.family_member{
  border: 3px solid black;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
}

.family_member input,
.family_member label,
.family_member select,
.family_member textarea{
  width: 90%;
  margin: 2px auto;
  padding: 2px 5px;
}
.family_member #chooseHeight,
.family_member #whyNeed{
  width: 90%;
  margin: 0 auto;
}

.family_member select[id="height_ft"],
.family_member select[id="height_in"],
.family_member select[id="height_"]{
  width: 60px;
  margin: 0 5px;
}

.addFamilyMember,
.removeFamilyMember{
  width: 100%;
  background-color: green;
  border-radius: 5px;
  color: white;
  margin: 10px auto;
  font-size: 15px;
}

.removeFamilyMember{
  background-color: red;
}

.deleteSpotButton,
.deleteSpotButton:hover{
  margin: 0 0 0 10px;
  padding: 2px 4px;
  font-size: small;
  width: auto;
  background-color: red;
  color: white;
  box-shadow: 2px 2px 1px gray;
  border-radius: 5px;
}

.deleteSpotButton:hover{
  background-color: darkred;
}

.textArea{
  max-width: 100%;
  max-height: 100%;
}