.headerText{
    display: flex;
    height: 180px;
    max-width: 1200px;
}
.leftText{
    width: 100%;
    display: flex;
}
.leftText{
    width: 80%;
    height: 0px;
    margin: 0 auto;
}

.imageRight {
    margin: 0 auto;
    width: 66%;
    height: 160px;
    display: flex;
    flex-direction: column;
    /* max-height: 250px;
    min-height: 200px;
    max-width: 250px;
    min-width: 250px; */
}

.imageRight img{
    height: 50%;
    width: 100%;
    object-fit: contain;
}

#titleMobile{
    width: 50%;
    margin: 0 auto;
}

.starResults {
    font-size: 1.8rem;
    text-align: center;
    padding-top: 20px;
    color:white;
    background-color: #5b2b9c;
    height: 120px;
    padding: 20px;
    border-radius: 50%;
    box-shadow: 5px 5px 10px #17092b;
    } 

.hugeText{
    font: 4rem;
}

@media (max-width: 1000px) {
    .starResults {
        font-size: 0.8rem;
        width: 33%;
        height: 90px;
        }
    .headerText{
        /* background-color: blue; */
        flex-direction: row;
        /* height: 200px; */
    }
    .imageRight{
        width: 50%;
       
    }

    .imageRight img{
        height: 180px;
    }
    .leftText{
        width: 100%;
    }
    .leftText p{
        font-size: 0.7rem
    }
   
}
 
